import "./src/styles/global.css"
import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import Theme from "./src/themes/theme"

import "./language-tabs.css"
// Highlighting for code blocks
// import("prismjs/themes/prism.css")
// import("prismjs/themes/prism-solarizedlight.css")
// import("prismjs/themes/prism-coy.css")
import("prismjs/themes/prism-tomorrow.css")

const GlobalStyles = createGlobalStyle`
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body, html{
  font-family: ${props => props.theme.fonts.main};
  height: 100%;
  background-color: ${props => props.theme.colors.white2};
}
`

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    {element}
  </ThemeProvider>
)
export const onInitialClientRender = () => {
  document.getElementById("___loader").style.display = "none"
}
